<script>
  import { onMount } from 'svelte'
  // import { navigateTo } from 'svelte-router-spa'
  import { token, filterDockingStore } from '../../stores'
  import Icon from 'svelte-awesome'
  import { refresh, download, plus } from 'svelte-awesome/icons'
  import { Datatable, SearchInput } from 'svelte-simple-datatables'

  import VesselModal from '../components/VesselModal.svelte'
  import { getAllVessels } from '../../services/vessel'
  import { loginSkutan } from '../../services/skutanFetch'
  import csvVesselExport from '../components/csvVesselExport'
  // import Vessel from './Vessel.svelte'

  // import { lightBlue, lightGreen } from '../../constants/colors';

  let showModal = false
  let numOfRows = 35
  let vesselList = []
  let loading = true
  let rows
  // let appUrl = 'https://skraning.slipp.is/roles'
  const settings = {
    columnFilter: true,
    CSS: true,
    rowsPerPage: 35,
    labels: {
      search: 'Leita...',
    },
    blocks: {
      searchInput: false,
      paginationButtons: true,
      paginationRowCount: true,
    },
  }

  onMount(async () => {
    //Gets all vessels from database
    const response = await getAllVessels($token)
    if (response) {
      vesselList = response
      // console.log(response)
      //Set the body inspection date and axle inspection date
      for (let i = 0; i < vesselList.length; i++) {
        vesselList[i].bodyInspection = cutInspectionString(vesselList[i].bodyInspection.body_inspection)
        vesselList[i].axleInspection = cutInspectionString(vesselList[i].axleInspection.axle_inspection)
        if (vesselList[i].length && vesselList[i].length != "") {
          vesselList[i].length = parseFloat(vesselList[i].length).toFixed(2).toLocaleString('en-US').replace('.', ',')
        }
        else{
          vesselList[i].length = "---"
        }
      }
      loading = false
 
      //console.log(vesselList)
      //console.log("S")
    } else {
      console.log('ERROR getting all vessels ')
    }
  })

  //TODO, this does not work since ip is not allowed 
    onMount(async () => {
    //Gets all vessels from database
    await loginSkutan()
  })

  

  const openVessel = (vesselId) => {
    // const route = `vessel/${vesselId}`
    // navigateTo(route)
    window.open(`https://skraning.slipp.is/vessel/${vesselId}`)
  }

  function filterByValid(vessel) {
    if (vessel && (vessel.id || vessel.imo)) {
      return true
    }
    return false
  }

  function filterByDockingDate(vessel) {
    if (filterByValid(vessel) && vessel.hasDocking) {
      return true
    }
    return false
  }
  const openModal = () => {
    showModal = true
  }

  function handleModalClosed() {
    showModal = false
  }

  //If a new owner is "created" we re-render all the vessels
  async function handleNewVessel() {
    const newVesselResponse = await getAllVessels($token)
    if (newVesselResponse) {
      vesselList = newVesselResponse
    } else {
      console.log('ERROR creating or updating a vessel')
    }
  }

  function cutInspectionString(theString) {
    //Cuts inspection strings and returns the date the inspection ended

      let checkString = theString[0][0].split('Notandi')[0]
    if (checkString == theString) {
      return '---'
    }
    let retString = checkString.split(' ')
    //Some dates have a random underscore instead of a date, so we just return dashes
    if (retString[retString.length - 2] == '____') {
      return '---'
    }
    let dateSplit = retString[retString.length - 2].split('.')
    let yy = dateSplit[2]
    let mm = dateSplit[1]
    let dd = dateSplit[0]

    let retDate = new Date(yy, mm-1, dd).toISOString().slice(0, 10)
    return retDate

  }

  function lastDockingDate(row) {
    var today = new Date()
    var dateSince = new Date(row.lastDockingDate)
    var diff = today.getTime() - dateSince.getTime()
    var retDays = Math.ceil(diff / (1000 * 3600 * 24))
    if (row.inProcess) {
      return 'Í sáturlista'
    }
    if (retDays <= 0) {
      return 'Á leið í slipp'
    }
    return Math.ceil(diff / (1000 * 3600 * 24)).toLocaleString('de-DE')
  }
  //Create a csv file and download it
  //Creates a csv file from the data in the current pagination, based on filters,search and page number
  function convertToCsv() {
    //When user clicks this button a message pops up asking how many rows the user wants to print out
    //this way the user can choose exactly what he prints, he can choose all the rows available
    //Changes rowsperpage number from 35 to x
    csvVesselExport.convertToCsv($rows)
  }
  const changeNumOfRows = (val) => {
    //Changes the number of rows displayed
    //console.log(val)
    if (val <= 0) {
      val = 1
    } else if (val >= 1100) {
      val = 1100
    }
    settings.rowsPerPage = parseInt(val)
  }


</script>

<VesselModal showModal="{showModal}" on:modalClosed="{handleModalClosed}" on:vesselCreated="{handleNewVessel}" />

<div>
  {#if loading}
    <div class="section">
      <Icon spin data="{refresh}" scale="3" />
    </div>
  {:else}
    <div class="level">
      <div class="level-item has-text-centered">
        <h1 class="title is-3 has-text-centered" style="display: inline;">Þilfarskip</h1>
      </div>
    </div>
    <div>
      <div class="level">
        <div class="level-left">
          <SearchInput class="input" id="{'vesselTable'}" />
          <label class="checkbox" style="margin-bottom: 0rem">
            <input type="checkbox" style="margin-left: 10px; transform: scale(1.2)" bind:checked="{$filterDockingStore}" />
            Hafa farið í slipp
          </label>
        </div>
        <div class="rightButtonLevel">
          <article class="message is-info">
            <div class="message-body" style="align-self: end; margin-right: 0.75rem; padding: 0.5rem"> Fjöldi raða sem sjást í listanum</div>
          </article>
          <div class="field has-addons">
            <div class="control">
              <input class="input" type="number" min="0" max="1100" bind:value="{numOfRows}" on:change="{(e) => changeNumOfRows(e.target.value)}" />
            </div>
            <div class="control">
              <button class="button is-warning" style="margin-right: 16px;" on:click="{() => convertToCsv()}">
                <span class="icon is-small">
                  <Icon data="{download}" scale="1" />
                </span><span>Sækja CSV</span></button
              >
            </div>
          </div>
          <button class="button is-success" style="margin-right: 16px;" on:click="{() => openModal()}">
            <span class="icon is-small">
              <Icon data="{plus}" scale="1" />
            </span><span>Stofna skip</span>
          </button>
        </div>
      </div>

      <div style="min-height:90vh; ">
        <Datatable settings="{settings}" data="{$filterDockingStore ? vesselList.filter(filterByDockingDate) : vesselList.filter(filterByValid)}" bind:dataRows="{rows}" id="{'vesselTable'}">
          <thead style="overflow-x: scroll">
            <tr style="overflow-x: scroll">
              <th data-key="id">Sknr</th>
              <th data-key="imo">IMO</th>
              <th data-key="name">Nafn</th>
              <th data-key="type">Tegund</th>
              <th data-key="owner">Útgerðaraðili</th>
              <th data-key="lastDockingDate">Fór seinast í slipp</th>
              <th style="white-space:normal; word-break: break-word; min-width: none" data-key="location">Staður slipps/ eða þjónustu</th>
              <th style="white-space:normal; word-break: break-word;" data-key="dockName">Upptökumannvirki</th>
              <th data-key="lastDockingDate">Dagar síðan</th>
              <th data-key="bodyInspection">Bolskoðun</th>
              <th data-key="axleInspection">Öxulskoðun</th>
              <th data-key="length">Lengd</th>
            </tr>
          </thead>
          <tbody style="overflow-x: scroll">
            {#if rows}
              {#each $rows as row}
                <tr class="clickable" style="text-align: center;" on:click="{() => openVessel(row.id)}">
                  <td style="white-space:normal; inline-size: 150px; word-break: break-word;">{row.id}</td>
                  <td>{row.imo ? row.imo : "---"}</td>
                  <td>{row.name ? row.name : "---"} </td>
                  <td>{row.type ? row.type : "---"}</td>
                  <td>{row.owner ? row.owner : "---"}</td>
                  <td>{row.lastDockingDate ? row.lastDockingDate : '---'}</td>
                  <td>{row.location ? row.location : '---'}</td>
                  <td>{row.dockName ? row.dockName : '---'}</td>
                  <td style="{`background-color:${row.inProcess ? `whitesmoke` : 'none'}; white-space:normal; inline-size: 120px; word-break: break-all;`}">{row.lastDockingDate ? lastDockingDate(row) : '---'}</td>
                  <td>{row.bodyInspection}</td>
                  <td>{row.axleInspection}</td>
                  <td>{row.length ? row.length : "---"}</td>
                </tr>
              {/each}
            {/if}
          </tbody>
        </Datatable>
      </div>
    </div>
  {/if}
</div>

<style>
  tr:hover {
    background: whitesmoke;
  }
  th {
    min-width: none;
  }
  /* .rowInputDiv {
    margin-right: 16px;
    align-self: end;
  } */
  .rightButtonLevel {
    display: flex;
    justify-content: flex-start;
  }
</style>
