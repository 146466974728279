<script>
  import { Route, navigateTo } from 'svelte-router-spa'
  import { onMount } from 'svelte'
  import Icon from 'svelte-awesome'
  import { refresh, externalLink } from 'svelte-awesome/icons'
  import { token } from '../../stores'
  import VesselModal from '../components/VesselModal.svelte'
  import { getVesselById } from '../../services/vessel'

  export let currentRoute
  let showModal = false
  let routeVesselId = currentRoute.namedParams.vesselId
  let vessel
  let loading = true

  onMount(async () => {
    //Gets a specific vessel by the id in the route / search params
    const response = await getVesselById($token, routeVesselId)
    if (response) {
      loading = false
      vessel = response
      //console.log(vessel)
      document.title = vessel.name //Set a new page title so that it is easier to know what is in what tab
    } else {
      console.log('ERROR getting vessel by id :', routeVesselId)
    }
  })

  


  const openCreateModal = () => {
    showModal = true
  }

  function handleModalClosed() {
    showModal = false
  }

  function handleNewVessel(event) {
    vessel = event.detail.vessel
  }
</script>

<VesselModal showModal="{showModal}" on:modalClosed="{handleModalClosed}" modify="{true}" vessel="{vessel}" on:vesselCreated="{handleNewVessel}" />
{#if loading}
  <div class="section">
    <Icon spin data="{refresh}" scale="3" />
  </div>
{:else}
  <div class="level">
    <div class="level-item has-text-centered">
      <h1 class="title is-2 clickable" on:click="{() => navigateTo(`vessel/${routeVesselId}`)}">{vessel.name ? vessel.name : 'Unnamed vessel'}</h1>
    </div>
  </div>
  <div class="level" style="margin-top: -20px;">
    <div class="level-item has-text-centered">
      <h2 class="subtitle">{vessel.type.name ? vessel.type.name + ' - ' : ''}{vessel.port.name ? vessel.port.name : ''}</h2>
    </div>
  </div>
  <div class="columns is-gapless ">
    <div class="column is-2.5" style="padding-bottom:3rem">
      <div class="box">
        <p class="title is-5">Auka hlekkir</p>
        <span class="icon-text">
          <a target="_blank" rel="noopener noreferrer" href="https://www.mbl.is/200milur/skipaskra/skip/{vessel.id}/" alt="https://www.mbl.is/200milur/skipaskra/">
            <span class="icon">
              <Icon data="{externalLink}" />
            </span>
            Fara á skipaskrá mbl
          </a>
        </span>

        <br />
        <span class="icon-text">
          <a target="_blank"rel="noopener noreferrer"  href="{vessel.imo ? `https://www.marinetraffic.com/en/ais/details/ships/imo:${vessel.imo}` : 'https://www.marinetraffic.com/en/ais/home/centerx:-18.092/centery:65.696/zoom:14'}" alt="https://www.marinetraffic.com/en/ais/home/centerx:-18.092/centery:65.696/zoom:14">
            <span class="icon">
              <Icon data="{externalLink}" />
            </span>
            Fara á marinetraffic
          </a>
        </span>
      </div>
    </div>
    <div class="column is-centered has-text-centered">
      <div style="display: flex; gap: 0.4rem; justify-content: center;"
        ><strong>Sknr:</strong>
        <p class="has-text-link">{vessel.id}</p><br /></div
      >
      <strong>IMO:</strong>
      {vessel.imo ? vessel.imo : ''}<br />
      <strong>MMSI:</strong>
      {vessel.mmsi ? vessel.mmsi : ''}<br />
      <br />
      <strong>Eigandi:</strong>
      {vessel.owner.name ? vessel.owner.name : ''}<br />
      <strong>Kennitala:</strong>
      {vessel.owner.kennitala ? vessel.owner.kennitala : ''}<br />
      <br />
      <!-- {#if vessel.owner.kennitala != vessel.operator.kennitala} -->
      <strong>Útgerðaraðili:</strong>
      {vessel.operator.name ? vessel.operator.name : ''}<br />
      <strong>Kennitala:</strong>
      {vessel.operator.kennitala ? vessel.operator.kennitala : ''}<br />
      <!-- {/if} -->
    </div>

    <div class="column is-centered has-text-centered">
      <strong>Smíðaár:</strong>
      {vessel.buildYear ? vessel.buildYear : ''}<br />

      <strong>Klassi:</strong>
      {vessel.class.name ? vessel.class.name : ''}<br />
      <strong>Veiðileyfi í gildi:</strong>
      <tr style="display:flex; justify-content: center;">
        <td>
          {#if vessel.license}
            {#each vessel.license as l}
              {l.name} <br />
            {/each}
          {/if}
        </td>
      </tr>
    </div>

    <div class="column is-centered has-text-centered">
      <strong>Bol efni:</strong>
      {vessel.hullMaterial.name ? vessel.hullMaterial.name : ''}<br />
      <strong>Lengd:</strong>
      {vessel.length ? vessel.length : ''}<br />
      <strong>Mesta lengd:</strong>
      {vessel.maxLength ? vessel.maxLength : ''}<br />
      <br />
      <strong>Breidd:</strong>
      {vessel.breadth ? vessel.breadth : ''}<br />
      <strong>Dýpt:</strong>
      {vessel.depth ? vessel.depth : ''}<br />
      <br />
      <strong>Bruttótonn:</strong>
      {vessel.grossWeight ? vessel.grossWeight : ''}<br />
      <strong>Bruttó rúmmál:</strong>
      {vessel.grossVolume ? vessel.grossVolume : ''}<br />
    </div>

    <!-- <div class="column">
      
    </div> -->
    <div class="column">
      <div class="buttons is-right">
        <button class="button is-info" on:click="{() => openCreateModal()}">Uppfæra upplýsingar fyrir þilfarskip</button>
      </div>
    </div>
  </div>
  <hr />

  <br /><br />
{/if}

<Route currentRoute="{currentRoute}" />
