import axios from 'axios'
import { toaster } from '../helpers'
import { skutanBearerToken } from '../stores'
export async function getVesselFromSkutan1() {
  try {
    const response = await axios.get(
      'https://api-skutantest.samgongustofa.is/api/v1/ships/81',
      // { withCredentials: false },
      //{ mode: 'no-cors' },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Access-Control-Allow-Headers': 'Content-Type',
          proxy: 'https://api-skutantest.samgongustofa.is/api/v1',
          //'Content-Type': 'application/json',
          // 'Accept-Language': 'en-GB,en;q=0.9,en-US;q=0.8',
          Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJTYW1nw7ZuZ3VzdG9mYSIsImV4cCI6MTcwOTgyMTEzMCwiaWF0IjoxNzA5ODA2NzMwLCJ1c2VyIjoiMTg2NjEifQ.UgMnMZ2TcwNSRyVzkis6THginM6WVU07CDlI_Z6v3Zg',
          // Connection: 'keep-alive',
          //Cookie:
          //'skutan_session=eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImNzcmZUb2tlbiI6IjNlMWUzNTA1ZTAyYTdiOTkxZjg5NjRjZDkzMTY0NDM1MDRjZjdlMjQtMTcwOTgxNzkxMTg2NS04ZDgzY2EyMWM1NzNkY2QzZjk4Y2U5NGYifSwiZXhwIjoxNzA5ODQ2NzExLCJuYmYiOjE3MDk4MTc5MTEsImlhdCI6MTcwOTgxNzkxMX0.fVmrBw8ZwmXuzHoDC9iaeo-TbcdcvUf9Ljst4EpEomQ',
          // Referer: 'https://api-skutantest.samgongustofa.is/api/v1/swagger',
          // 'Sec-Fetch-Dest': 'empty',
          // 'Sec-Fetch-Site': 'same-origin',
          // 'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36 Edg/122.0.0.0',
          // accept: 'application/json',
          accept: 'application/json',
        },
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
  }
}

export async function getVesselFromSkutan() {
  console.log('FETCH')
  const res = await fetch('https://api-skutantest.samgongustofa.is/api/v1/ships/81', {
    headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJTYW1nw7ZuZ3VzdG9mYSIsImV4cCI6MTcwOTgzNDE4MywiaWF0IjoxNzA5ODE5NzgzLCJ1c2VyIjoiMTg2NjEifQ.ZGS8dk4cwAAA49shORmx9QHHf-Qg7RTqajSQ3I_0zSo' },
  })
  const weather = await res.json()

  return { body: weather }
}

export async function loginSkutan() {
  console.log('post to login')

  try {
    const loginResponse = await axios.post('https://api-skutan.samgongustofa.is/api/v1/oauth/token', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
        'Content-Type': 'application/json',
        grant_type: 'client_credentials',
        accept_encoding: 'gzip, deflate, br',
        client_id: 'ws_slipp_fridrik',
        client_secret: 'ws_slipp_fridrik',
        scope: 'read_only_inspections',
      },
    })
    let message = 'Skutan info received'
    toaster(message)
    skutanBearerToken.set(loginResponse.json()['access_token'])
  } catch (e) {
    let message = e.response.data.message
    console.log(message)
    toaster(message)
  }
}
